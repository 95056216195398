// Copyright 2021
// Polus
import * as React from 'react';

import Layout from '../components/Layout';
import Seo from '../components/SEO';

const NotFoundPage = (): React.ReactElement => (
  <Layout>
    <Seo title="404: Not found" />
    <h1>Nothing exists here</h1>
    <p>Sadly, we couldn't find what you were looking for...</p>
  </Layout>
);

export default NotFoundPage;
